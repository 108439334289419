<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12"><h1>Contact us</h1></div>
    </div>
    <!--    <form method="post" name="accFrm" id="accFrm" enctype="multipart/form-data">-->
    <div class="row">
      <div id="messageRow" class="col-sm-12"></div>
      <div class="form-group col-sm-6">
        <label class="control-label label-require">Name </label>
        <input class="form-control" name="name" id="name" placeholder="Name" type="text" />
        <ErrorFormField :msgArray="$data.errorFields.name" />
      </div>
      <div class="form-group col-sm-6">
        <label class="control-label label-require">Email</label>
        <input class="form-control" name="email" id="email" placeholder="Email" type="text" />
        <ErrorFormField :msgArray="$data.errorFields.email" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label class="control-label label-require">Phone Number </label>
        <input
          class="form-control"
          name="phone"
          id="phone"
          placeholder="Phone Number"
          type="text"
        />
        <ErrorFormField :msgArray="$data.errorFields.phone" />
      </div>
      <div class="form-group col-sm-6">
        <label class="control-label"> Type of question </label>
        <select class="selectpicker form-control languages-select" name="question" id="question">
          <option value="1">Emergency</option>
          <option value="2" selected>Feedback</option>
          <option value="3">Investors</option>
          <option value="4">Legal</option>
          <option value="5">Partnerships</option>
          <option value="6">Press & Media</option>
          <option value="7">Safety</option>
        </select>
      </div>
    </div>
    <div class="row">
      <!--            <div class="form-group col-sm-6 "> <label class="control-label">Location</label>                <input class="form-control street_address_1" name="route" id="street_address_1" value="" placeholder="Location" type="text">            </div>-->
      <div class="form-group col-sm-6">
        <label class="control-label"
          >Location
          <a
            class="icon-info"
            href="javascript:;"
            data-toggle="tooltip"
            title="Street/Locality Address, P.O. Box, C/O, etc."
          ></a>
        </label>
        <input
          class="form-control address"
          name="address"
          id="address"
          placeholder="Location"
          type="text"
        />
      </div>
      <div class="form-group col-sm-6">
        <!--                                <label class="control-label">Deadline</label> -->
        <!--                                <input class="form-control" name="deadline" id="deadline" placeholder="Deadline"     type="text">-->
        <!--                                <p class="text-danger err_msg" id="err_deadline"></p>-->
        <label class="control-label">Priority</label>
        <select class="form-control" name="priority" id="priority">
          <option value="1">Low</option>
          <option value="5" selected>Medium</option>
          <option value="9">High</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="control-label">Message <span class="mandatory">*</span></label>
        <textarea
          class="form-control"
          name="msg"
          id="msg"
          rows="5"
          placeholder="Message"
        ></textarea>
        <p id="counter"></p>
        <ErrorFormField :msgArray="$data.errorFields.msg" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 text-success">
        <p>{{ $data.resultMessage }}</p>
      </div>
      <div class="form-group col-md-offset-3 col-md-6 col-sm-offset-0 col-sm-12">
        <LoadingIcons total="3" v-if="$data.ajax.isSubmitting" />
        <button class="btn btn-primary" id="getRatePeople" @click="submitForm" v-else>
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
export default {
  name: 'ContactUs',
  components: { LoadingIcons, ErrorFormField },
  data: function () {
    return {
      ajax: {
        isSubmitting: false,
      },
      errorFields: {
        name: [],
        email: [],
        phone: [],
        msg: [],
      },
      resultMessage: 'Your question will be addressed and responded within 24 hours.',
    };
  },
  created() {
    document.title = 'Trepr - Contact Us | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', 'If you have any query regarding our services, Contact us here.');
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,trerp contact us, Travel Contact Us, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
  methods: {
    submitForm: async function () {
      this.$data.errorFields.name = [];
      this.$data.errorFields.email = [];
      this.$data.errorFields.phone = [];
      this.$data.errorFields.msg = [];
      let name = document.getElementById('name').value;
      let phone = document.getElementById('phone').value;
      let email = document.getElementById('email').value;
      let question = document.getElementById('question').value;
      let address = document.getElementById('address').value;
      let priority = document.getElementById('priority').value;
      let msg = document.getElementById('msg').value;

      this.$data.ajax.isSubmitting = true;
      let data = {
        name: name,
        phone: phone,
        email: email,
        question: question,
        address: address,
        priority: priority,
        msg: msg,
      };

      // console.log('data ', data);
      // return;
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      // console.log('form data ', formData);
      let apiUrl = process.env.VUE_APP_API_URL;

      let self = this;
      await fetch(apiUrl + 'common/contact-us', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            // console.log('get data error for service rate people  ', messages);
            self.$data.errorFields.name = messages.name;
            self.$data.errorFields.email = messages.email;
            self.$data.errorFields.phone = messages.phone;
            self.$data.errorFields.msg = messages.msg;
            self.$data.ajax.isSubmitting = false;
            return false;
          }

          // self.$store.dispatch('loginUpdate', resData)
          self.$data.ajax.isSubmitting = false;
          self.$data.resultMessage = resData.message;
          // console.log('get data success for service rate people : ', resData);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  },
};
</script>

<style scoped></style>
